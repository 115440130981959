document.addEventListener('submit', e => {
	const question = (<HTMLElement>e.target).closest<HTMLFormElement>('form#question');
	if (!question) {
		return
	}
	e.preventDefault();
	if (!question.reportValidity()) {
		return
	}

	sendNotification(question)
});

document.addEventListener('submit', e => {
	const mail = (<HTMLElement>e.target).closest<HTMLFormElement>('form#mail');
	if (!mail) {
		return
	}
	e.preventDefault();
	if (!mail.reportValidity()) {
		return
	}

	sendNotification(mail)
});

function sendNotification(form: HTMLFormElement) {
	const formData = new FormData(form);
	const BHM7Vo = formData.get('BHM7Vo')?.toString();
	const subject = formData.get('objet')?.toString();
	const email = formData.get('email')?.toString();
	const contact = formData.get('contact')?.toString();
	const timestamp = parseInt(formData.get('timestamp')?.toString() ?? '0');
	const mailto = formData.get('mailto')?.toString();
	const txKPav = formData.get('txKPav')?.toString();
	const num = formData.get("num")?.toString();
	const category = formData.get("cat_title")?.toString();
	const type = formData.get("q_or_s")?.toString();
	const params = {
		subject,
		BHM7Vo,
		email,
		contact,
		timestamp,
		mailto,
		txKPav,
		num,
		category,
		type
	};
	const headers: HeadersInit = {
		'content-type': 'application/json',
	}
	fetch(`/result/${form.dataset.reportId}/notification`, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(params)
	}).then(async (res) => {
		const JSONResponse = await res.json();
		if (JSONResponse.type === "success" && form.id === "question") {
			form.innerHTML = `<header><h2>Message envoyé</h2></header>
							<div class="content thin"><br/>
							<p class="explication">Merci de l'intérêt porté à MilleCheck.</p>
							<p class="explication">Nous avons reçu votre question et nous nous efforçerons d'y répondre dans les 48 heures.</p>
							<p class="explication">Si vous n'obtenez pas de réponse d'ici là,<br/>
							 n'hésitez pas à nous envoyer un e-mail à contact@millecheck.ai .</p>
							<p class="explication">Cordialement,<br/>
							L'équipe MilleCheck.</p>
							</div>`;
		} else if (JSONResponse.type === "success" && form.id !== "question") {
			form.innerHTML = `<header><h2>Message envoyé</h2></header>
							<div class="content thin"><br/>
							<p class="explication">Merci de l'intérêt porté à MilleCheck.</p>
							<p class="explication">Cordialement,<br/>
							L'équipe MilleCheck.</p>
							</div>`;
		} else {
			form.innerHTML = `<header><h2>Message bloqué</h2></header>
							<div class="content thin"><br/>
							<p class="explication ${JSONResponse.type}">${JSONResponse.message}</p>
							<p class="explication">Vous pouvez nous envoyer un e-mail à contact@millecheck.ai comme solution alternative pour nous contacter.</p>
							<p class="explication">Cordialement,<br/>
							L'équipe MilleCheck.</p>
							</div>`;
		}


	});
}
